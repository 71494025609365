.fc-scroller{
  height: fit-content!important;
}

.cdk-overlay-container{ z-index: 1200 !important; }

.timepicker-overlay{ z-index: 1200 !important; }

.mat-form-field-flex{
  background-color: #E8F0FE;
  border-radius: 7px;
}

.formulario label{
  font-weight: bold;
  font-size: .8571em;
  margin-bottom: 5px;
  color: #9a9a9a;
}

.logos_upload{
  max-height: 100px!important;
  max-width: 100px!important;
}

.tbl-caja th{
  padding: 7px 7px!important;
}

.table-sm td, .table-sm th {
  padding: .3rem!important;
}

.card .avatar{
 margin-bottom: 0px!important;
}

.contenedor-foto{
  position: relative;
  border-radius: 0;
  display:inline-block;
  padding:20px;
}

.contenedor-foto span{
  left:0;
  text-align:center;
  width:100%;
  /* background:rgba(255,255,255,0.5); */
  background:#2ca7ff8e;
  bottom:0;
  padding:6px 0;
  position: absolute;
  color: white;
  transition: all .25s ease-in-out;
}

.contenedor-foto span:hover{
  padding:9px 0;
  background:#2ca7fff3;
}

.contenedor-foto .divcontainer{
  border-radius: 50%;
  position: relative;
  padding: 0;
  cursor: pointer;
  display:inline-block;
  overflow: hidden;
}

.btn-foto{
  position: absolute!important;
  right: 0px;
  bottom: -60px;
}

.btn-tamano{
  position: absolute!important;
  right: 0px;
  top: -10px;
  height: 30px!important;
}

.divmt {
  margin: 0px!important;
  display: none;
  opacity: 0;
  transition: all .3s linear;
}

.divmt h5 {
  margin: 0px!important;
}

.divm {
  display: none;
  opacity: 0;
  transition: all .3s linear;
}

.is-visible {
  display: block;
}

.is-open {
  margin-top: 15px;
  opacity: 1;
}

.chat-div{
  z-index: 1000;
  position: fixed;
  bottom: 25px;
  right: 35px;
}

.chat-div button{
  box-shadow: 5px 5px 15px 0 rgb(46 61 73 / 50%);
}

/* Add styles to the form container */
.form-container {
  z-index: 1000;
  position: fixed;
  bottom: 25px;
  right: 35px;
  box-shadow: 5px 5px 25px 0 rgb(46 61 73 / 50%);
  max-width: 500px;
  min-width: 500px;
  padding: 10px;
  background-color: white;
  border-radius: 6px;
}

@media (max-width: 660px){
  .form-container{
    max-width: 85%;
    min-width: 85%;
  }
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  border: none;
  background: white;
  resize: true;
  min-height: 30px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: white;
  outline: none;
}

.contenedor-mensajes{
  max-height: 400px;
}

.mensaje {
  background-color: #ECF1F8;
  width: fit-content;
  margin-bottom: 5px!important;
  border-radius: 3.3px!important;
}

.mensaje-saliente {
  color: white;
  background-color: #2ca8ff;
  width: fit-content;
  margin-bottom: 5px!important;
  border-radius: 3.3px!important;
  float: right!important;
  margin-right: 10px;
}

.mensaje p{
  padding: 5px;
  margin: 0px!important;
}

.mensaje-saliente p{
  padding: 5px;
  margin: 0px!important;
  text-align: right;
}

.detalle-mensaje{
  padding: 5px;
  font-size: 10px;
}

.cantidad{
  position: absolute;
  z-index: 1;
}

.tamanodonto{
  width: 1350px!important;
}
