table{
  width: 100%!important;
}
.colorR{
  background-color: #FE5B5C;
}
.colorA{
  background-color: #ffc700;
}
.colorID{
  color: white;
  font-weight: bold;
}
.diente {
    position: static;
    width: 0px;
    height: auto;
    margin-left: 50px;
    display: inline-block;
}

.cuadro {
    background-color: #FFFFFF;
    border: 1px solid #7F7F7F;
    position: relative;
    width: 25px;
    height: 15px;
    left: 45px;
    -webkit-border-radius: 80px 80px 0px 15px;
    -moz-border-radius: 80px 80px 0px 15px;
    border-radius: 80px 80px 0px 15px;
}

.cuadro:hover {
    /*background: rgba(117, 198, 243, 0.4);*/

    cursor: pointer;
}

.izquierdo {
    top: 1px !important;
    left: 28px !important;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
}

.debajo {
    top: 2px !important;
    left: 45px !important;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.derecha {
    top: -29px !important;
    left: 61px !important;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.centro {
    background: #F3F3F3;
    border: 1px solid #7F7F7F;
    width: 25px;
    height: 25px;
    top: -49px;
    left: 45px;
    position: relative;
}

.centro:hover {
    border: 1px solid rgba(117, 198, 243, 0.4);
    /*background-color: rgba(117, 198, 243, 0.4);*/

    cursor: pointer;
}

/* #tlr {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
}
#tr {
    border-right: 1px solid black;
}
#tll {
    border-bottom: 1px solid black;
    border-left: 1px solid black;
}
#tl {
    border-left: 1px solid black;
}

#blr {
    border-right: 1px solid black;
}
#br {
    border-right: 1px solid black;
}
#bl {
    border-left: 1px solid black;
}
#bll {
    border-left: 1px solid black;
} */
.click-red {
    background-color: red !important;
}

.click-blue {
    background-color: blue !important;
}

.click-delete {
    background-color: #747F7D !important;
}

.kill {
    background-color: green !important;
}

.click-clear {
    background-color :#F3F3F3 !important;
}

.diente-leche {
    position: relative;
    width: 0px;
    height: auto;
    margin-left: 50px;
    display: inline-block;
}

.cuadro-leche {
    border: 1px solid #7F7F7F;
    position: relative;
    width: 13px;
    height: 13px;
    left: 45px;
    -webkit-border-radius: 80px 80px 0px 15px;
    -moz-border-radius: 80px 80px 0px 15px;
    border-radius: 80px 80px 0px 15px;
}

.cuadro-leche:hover {
    /*background: rgba(117, 198, 243, 0.4);*/
    border: 1px solid rgba(117, 198, 243, 0.4);
    cursor: pointer;
}

.izquierdo-leche {
    top: -4px !important;
    left: 37px !important;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
}

.top-leche {
    width: 15px;
    left: 47px;
}

.debajo-leche {
    width: 15px;
    top: -8px !important;
    left: 47px !important;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.derecha-leche {
    top: -30px !important;
    left: 58px !important;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.centro-leche {
    background: #F3F3F3;
    border: 1px solid #7F7F7F;
    width: 15px;
    height: 13px;
    top: -43px;
    left: 47px;
    position: relative;
}

.centro-leche:hover {
    border: 1px solid rgba(117, 198, 243, 0.4);
    /*background-color: rgba(117, 198, 243, 0.4);*/

    cursor: pointer;
}


.fa-RR:before {
    font-family: Arial; /* your font family here! */
    font-weight: bold;
    position: absolute;
    content: 'RR';
    color: red;
    font-weight: 700;
    font-size: .6em;
    left: 10px;
    top: 9px;
}

.ppr:before {
    font-family: Arial; /* your font family here! */
    font-weight: bold;
    position: absolute;
    content: 'PPR';
    color: blue;
    font-weight: 700;
    font-size: 1em;
    left: 45%;
    top: 5px;
}

.fa-circle:before {
    font-size: .6em;
}

.odontocontent {
    max-width: 1140px;
    min-width: 1140px;
}

.grupobotones{
    position: relative;
    display: -ms-inline-flexbox;
    /* display: inline-flex; */
    vertical-align: middle;
}

.btn-claro {
    border:1px !important;
    background-color: transparent !important;
    color: #000000 !important;
    border:1px solid  #17a2b8 !important;
}

.btn.btn-claro.active,
.btn.btn-claro:active {
    border:2px solid  #17a2b8 !important;
    background-color: transparent !important;
}

.iconocirculo{
    font-size: 1.15em;
}

.fa-RR-icono:before {
    font-family: Arial; /* your font family here! */
    font-weight: bold;
    content: 'RR';
    color: red;
    font-weight: 700;
    font-size: 1em;
}

.fa-MOVIL-icono:before {
    font-family: Arial; /* your font family here! */
    font-weight: bold;
    content: 'M';
    color: red;
    font-weight: 700;
    font-size: 1em;
}

.fa-DESGASTE-icono:before {
    font-family: Arial; /* your font family here! */
    font-weight: bold;
    content: 'D';
    color: red;
    font-weight: 700;
    font-size: 1em;
}
.fa-ENDO-icono:before {
  font-family: Arial; /* your font family here! */
  font-weight: bold;
  content: 'E';
  color: green;
  font-weight: 700;
  font-size: 1em;
}
.fa-IMPLANTE-icono:before {
  font-family: Arial; /* your font family here! */
  font-weight: bold;
  content: 'I';
  color: green;
  font-weight: 700;
  font-size: 1em;
}


.menu_historia{
    display: -webkit-inline-box;
}

.btn-sq-lg {
    width: 100% !important;
    height: 150px !important;
    padding-top:7%;
}

.btn-sq {
    width: 100px !important;
    height: 100px !important;
    font-size: 10px;
}

.btn-sq-sm {
    width: 50px !important;
    height: 50px !important;
    font-size: 10px;
}

.btn-sq-xs {
    width: 25px !important;
    height: 25px !important;
    padding:2px;
}

.tooltip-inner {
    max-width: 350px;
    text-align: justify;
    width: 350px;

}

.form-control {
    border-radius: 10px;
}

.sidebar-mini-icon {
    margin-right: 5px !important;
    margin-left: 10px !important;
}

.input-group-append .input-group-text, .input-group-prepend .input-group-text {
    border-radius: 10px ;
}

.input-group-text i {
    padding-right: 30px;
}

.circle:before {
    content: ' \25CF';
    font-size: 20px;
}

.diagonal:before {
    content: '\0337';
    font-size: 2.8em;
    padding-left: 0.39em;
}

.paloma:before {
  content: '\2713';
  font-size: 2em;
  font-size: 50px;
  font-weight: bold;
  font-style: normal;
}

.cruz:before {
  content: '\2716';
  font-size: 2em;
  font-size: 50px;
  font-weight: bold;
  font-style: normal;
}

.alert-warning {
    color: #856404 !important;
    background-color: #fff3cd !important;
    border-color: #ffeeba !important;
}

textarea.form-control {
    max-width: 100%;
    max-height: 80px;
    padding: 5px 5px !important;
    resize: none;
    border: .5px solid #e3e3e3 !important;
    border-bottom: 2px solid #e3e3e3 !important;
    border-radius: 6px !important;
    line-height: 2;
}

.color0{

    background-color: #696969;
}
.color1{

    background-color: #00FF00;
}
.color2{

    background-color: #0101DF;
}
.color3{

    background-color: #8904B1;
}
.color4{

    background-color: #2ECCFA;
}
.color5{

    background-color: #DF013A;
}
.color6{

    background-color: #FF0000;
}
.color7{

    background-color: #CCCC1D;
}
.color8{

    background-color: #DC143C;
}
.color9{

    background-color: #FF4500;
}
.color10{

    background-color: #FF8C00;
}
.color11{

    background-color: #4B0082;
}
.color12{

    background-color: #90EE90;
}
.color13{

    background-color: #00FF7F;
}
.color14{

    background-color: #ADFF2F;
}
.color15{

    background-color: #006400;
}
.color16{

    background-color: #008080;
}
.color17{

    background-color: #20B2AA;
}
.color18{

    background-color: #228B22;
}
.color19{

    background-color: #8B008B;
}
.color20{

    background-color: #DB7093;
}

.card label {
  font-weight: bold;
}

@media (max-width: 991px){
    /* body {
        font-size: 12px ;
    } */
  .texto{
      font-size: 12px !important;
  }

  /* .container {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: 15px !important;
      margin-left: 15px !important;
  } */

  .dientes{
      font-size: 14px  !important;
  }

    /* .container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: 15px !important;
        margin-left: 15px !important;
    } */

}
/*
@media (max-width: 1130px) {


    .profile-photo .profile-photo-small {
      margin-left: -2px;
    }
    .button-dropdown {
      display: none;
    }
    #minimizeSidebar {
      display: none;
    }
    .navbar .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }
    .navbar .navbar-collapse .input-group {
      margin: 0;
      margin-top: 5px;
    }
    .navbar .navbar-nav .nav-item:first-child {
      margin-top: 10px;
    }
    .navbar .navbar-nav .nav-item:not(:last-child) {
      margin-bottom: 10px;
    }
    .navbar .dropdown.show .dropdown-menu {
      display: block;
    }
    .navbar .dropdown .dropdown-menu {
      display: none;
    }
    .navbar .dropdown.show .dropdown-menu,
    .navbar .dropdown .dropdown-menu {
      background-color: transparent;
      border: 0;
      transition: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      width: auto;
      margin: 0px 1rem;
      margin-top: 0px;
    }
    .navbar .dropdown.show .dropdown-menu:before,
    .navbar .dropdown .dropdown-menu:before {
      display: none;
    }
    .navbar .dropdown-menu .dropdown-item:focus,
    .navbar .dropdown-menu .dropdown-item:hover {
      color: #FFFFFF;
    }
    .navbar.bg-white .dropdown-menu .dropdown-item:focus,
    .navbar.bg-white .dropdown-menu .dropdown-item:hover {
      color: #888;
    }
    .navbar .navbar-toggler-bar {
      display: block;
      position: relative;
      width: 22px;
      height: 1px;
      border-radius: 1px;
      background: #FFFFFF;
    }
    .navbar .navbar-toggler-bar+.navbar-toggler-bar {
      margin-top: 7px;
    }
    .navbar .navbar-toggler-bar+.navbar-toggler-bar.navbar-kebab {
      margin-top: 3px;
    }
    .navbar .navbar-toggler-bar.bar2 {
      width: 17px;
      transition: width .2s linear;
    }
    .navbar.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
      background-color: #888;
    }
    .navbar .toggled .navbar-toggler-bar {
      width: 24px;
    }
    .navbar .toggled .navbar-toggler-bar+.navbar-toggler-bar {
      margin-top: 5px;
    }
    .wrapper {
      -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .nav-open .main-panel {
      right: 0;
      -webkit-transform: translate3d(260px, 0, 0);
      -moz-transform: translate3d(260px, 0, 0);
      -o-transform: translate3d(260px, 0, 0);
      -ms-transform: translate3d(260px, 0, 0);
      transform: translate3d(260px, 0, 0);
    }
    .nav-open .sidebar {
      -webkit-transform: translate3d(0px, 0, 0);
      -moz-transform: translate3d(0px, 0, 0);
      -o-transform: translate3d(0px, 0, 0);
      -ms-transform: translate3d(0px, 0, 0);
      transform: translate3d(0px, 0, 0);
    }
    .nav-open body {
      position: relative;
      overflow-x: hidden;
    }
    .nav-open .menu-on-right .main-panel {
      -webkit-transform: translate3d(-260px, 0, 0);
      -moz-transform: translate3d(-260px, 0, 0);
      -o-transform: translate3d(-260px, 0, 0);
      -ms-transform: translate3d(-260px, 0, 0);
      transform: translate3d(-260px, 0, 0);
    }
    .nav-open .menu-on-right .navbar-collapse,
    .nav-open .menu-on-right .sidebar {
      -webkit-transform: translate3d(0px, 0, 0);
      -moz-transform: translate3d(0px, 0, 0);
      -o-transform: translate3d(0px, 0, 0);
      -ms-transform: translate3d(0px, 0, 0);
      transform: translate3d(0px, 0, 0);
    }
    .nav-open .menu-on-right .navbar-translate {
      -webkit-transform: translate3d(-300px, 0, 0);
      -moz-transform: translate3d(-300px, 0, 0);
      -o-transform: translate3d(-300px, 0, 0);
      -ms-transform: translate3d(-300px, 0, 0);
      transform: translate3d(-300px, 0, 0);
    }
    .nav-open .menu-on-right #bodyClick {
      right: 260px;
      left: auto;
    }
    .menu-on-right .sidebar {
      left: auto;
      right: 0;
      -webkit-transform: translate3d(260px, 0, 0);
      -moz-transform: translate3d(260px, 0, 0);
      -o-transform: translate3d(260px, 0, 0);
      -ms-transform: translate3d(260px, 0, 0);
      transform: translate3d(260px, 0, 0);
    }
    .bar1,
    .bar2,
    .bar3 {
      outline: 1px solid transparent;
    }
    .bar1 {
      top: 0px;
      -webkit-animation: topbar-back 500ms linear 0s;
      -moz-animation: topbar-back 500ms linear 0s;
      animation: topbar-back 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }
    .bar2 {
      opacity: 1;
    }
    .bar3 {
      bottom: 0px;
      -webkit-animation: bottombar-back 500ms linear 0s;
      -moz-animation: bottombar-back 500ms linear 0s;
      animation: bottombar-back 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }
    .toggled .bar1 {
      top: 6px;
      -webkit-animation: topbar-x 500ms linear 0s;
      -moz-animation: topbar-x 500ms linear 0s;
      animation: topbar-x 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }
    .toggled .bar2 {
      opacity: 0;
    }
    .toggled .bar3 {
      bottom: 6px;
      -webkit-animation: bottombar-x 500ms linear 0s;
      -moz-animation: bottombar-x 500ms linear 0s;
      animation: bottombar-x 500ms 0s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }
    @keyframes topbar-x {
      0% {
        top: 0px;
        transform: rotate(0deg);
      }
      45% {
        top: 6px;
        transform: rotate(145deg);
      }
      75% {
        transform: rotate(130deg);
      }
      100% {
        transform: rotate(135deg);
      }
    }
    @-webkit-keyframes topbar-x {
      0% {
        top: 0px;
        -webkit-transform: rotate(0deg);
      }
      45% {
        top: 6px;
        -webkit-transform: rotate(145deg);
      }
      75% {
        -webkit-transform: rotate(130deg);
      }
      100% {
        -webkit-transform: rotate(135deg);
      }
    }
    @-moz-keyframes topbar-x {
      0% {
        top: 0px;
        -moz-transform: rotate(0deg);
      }
      45% {
        top: 6px;
        -moz-transform: rotate(145deg);
      }
      75% {
        -moz-transform: rotate(130deg);
      }
      100% {
        -moz-transform: rotate(135deg);
      }
    }
    @keyframes topbar-back {
      0% {
        top: 6px;
        transform: rotate(135deg);
      }
      45% {
        transform: rotate(-10deg);
      }
      75% {
        transform: rotate(5deg);
      }
      100% {
        top: 0px;
        transform: rotate(0);
      }
    }
    @-webkit-keyframes topbar-back {
      0% {
        top: 6px;
        -webkit-transform: rotate(135deg);
      }
      45% {
        -webkit-transform: rotate(-10deg);
      }
      75% {
        -webkit-transform: rotate(5deg);
      }
      100% {
        top: 0px;
        -webkit-transform: rotate(0);
      }
    }
    @-moz-keyframes topbar-back {
      0% {
        top: 6px;
        -moz-transform: rotate(135deg);
      }
      45% {
        -moz-transform: rotate(-10deg);
      }
      75% {
        -moz-transform: rotate(5deg);
      }
      100% {
        top: 0px;
        -moz-transform: rotate(0);
      }
    }
    @keyframes bottombar-x {
      0% {
        bottom: 0px;
        transform: rotate(0deg);
      }
      45% {
        bottom: 6px;
        transform: rotate(-145deg);
      }
      75% {
        transform: rotate(-130deg);
      }
      100% {
        transform: rotate(-135deg);
      }
    }
    @-webkit-keyframes bottombar-x {
      0% {
        bottom: 0px;
        -webkit-transform: rotate(0deg);
      }
      45% {
        bottom: 6px;
        -webkit-transform: rotate(-145deg);
      }
      75% {
        -webkit-transform: rotate(-130deg);
      }
      100% {
        -webkit-transform: rotate(-135deg);
      }
    }
    @-moz-keyframes bottombar-x {
      0% {
        bottom: 0px;
        -moz-transform: rotate(0deg);
      }
      45% {
        bottom: 6px;
        -moz-transform: rotate(-145deg);
      }
      75% {
        -moz-transform: rotate(-130deg);
      }
      100% {
        -moz-transform: rotate(-135deg);
      }
    }
    @keyframes bottombar-back {
      0% {
        bottom: 6px;
        transform: rotate(-135deg);
      }
      45% {
        transform: rotate(10deg);
      }
      75% {
        transform: rotate(-5deg);
      }
      100% {
        bottom: 0px;
        transform: rotate(0);
      }
    }
    @-webkit-keyframes bottombar-back {
      0% {
        bottom: 6px;
        -webkit-transform: rotate(-135deg);
      }
      45% {
        -webkit-transform: rotate(10deg);
      }
      75% {
        -webkit-transform: rotate(-5deg);
      }
      100% {
        bottom: 0px;
        -webkit-transform: rotate(0);
      }
    }
    @-moz-keyframes bottombar-back {
      0% {
        bottom: 6px;
        -moz-transform: rotate(-135deg);
      }
      45% {
        -moz-transform: rotate(10deg);
      }
      75% {
        -moz-transform: rotate(-5deg);
      }
      100% {
        bottom: 0px;
        -moz-transform: rotate(0);
      }
    }
    @-webkit-keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @-moz-keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    #bodyClick {
      height: 100%;
      width: 100%;
      position: fixed;
      opacity: 1;
      top: 0;
      right: 0;
      left: 260px;
      content: "";
      z-index: 9999;
      overflow-x: hidden;
      background-color: transparent;
      -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .footer .copyright {
      text-align: right;
    }
    .section-nucleo-icons .icons-container {
      margin-top: 65px;
    }
    .navbar-nav .nav-link i.fa,
    .navbar-nav .nav-link i.now-ui-icons {
      opacity: .5;
    }
    .sidebar,
    .bootstrap-navbar {
      position: fixed;
      display: block;
      top: 0;
      height: 100%;
      width: 260px;
      right: auto;
      left: 0;
      z-index: 1032;
      visibility: visible;
      overflow-y: visible;
      padding: 0;
      -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      -webkit-transform: translate3d(-260px, 0, 0);
      -moz-transform: translate3d(-260px, 0, 0);
      -o-transform: translate3d(-260px, 0, 0);
      -ms-transform: translate3d(-260px, 0, 0);
      transform: translate3d(-260px, 0, 0);
    }
    .main-panel {
      width: 100%;
    }

  }

  @media screen and (min-width: 1131px) {
    .navbar-collapse {
      background: none !important;
    }
    .navbar .navbar-toggle {
      display: none;
    }
    .navbar-nav .nav-link.profile-photo {
      padding: 0;
      margin: 7px 0.7rem;
    }
    .section-nucleo-icons .icons-container {
      margin: 0 0 0 auto;
    }
    .dropdown-menu .dropdown-item {
      color: inherit;
    }
    .footer .copyright {
      float: right;
      padding-right: 15px;
    }
  }

  @media (min-width: 1130px) {
    .sidebar {
      display: block;
      box-shadow: 0px 2px 22px 0 rgba(0, 0, 0, 0.2), 0px 2px 30px 0 rgba(0, 0, 0, 0.35);
    }
  } */

  @media (max-width: 660px){
    .fc-left{
      float: none !important;
      display: inline-block;
    }
    .fc-center{
      padding-left: 10px;
      padding-right: 10px;
    }
    .fc-right{
      float: none !important;
      display: inline-block;
    }

  }
  @media (max-width: 767px){
    body {
        font-size: 11.5px !important;
    }
    h1{
      font-size:2rem!important;
    }
  }

  @media (min-width: 768px) {
    body {
        font-size: 11.5px !important;
    }
    h1{
      font-size:2.3rem!important;
    }
  }
  @media (min-width: 992px) {
    body {
        font-size: 12.5px !important;
    }
    h1{
      font-size:2.5rem!important;
    }
  }
  @media (min-width: 1200px) {
    body {
        font-size: 14px !important;
    }
    h1{
      font-size:2.7rem!important;
    }
  }
  @media (min-width: 1600px) {
    body {
        font-size: 14px !important;
    }
    h1{
      font-size:3rem!important;
    }
  }


  .etiqueta{
    font-size: 17px;
    color: white;
    padding: 5px;
    border: solid 1px white;
    background:rgba(0, 97, 243, 0.63);
    position:absolute;
    z-index:10001;
    border-radius:3px;
    box-shadow: 0px 2px 22px 0 rgba(0, 0, 0, 0.2), 0px 2px 22px 0 rgba(0, 0, 0, 0.2);
  }

  .dientes{
    max-width: 1140px;
    min-width: 1140px;
  }

  .numeros{
    margin-left: 50px;
    margin-bottom: 5px;
    display: flex;
    /* display: inline-block !important;  */
    /* border-radius: 10px !important; */
  }

  .btn-purple {
    background-color: #845fd0fc;
    color: #fff;
  }
  .btn-purple:hover{
    background-color: #9071cffc;
    color: #fff;
    box-shadow: none;
  }

  .myBox {
    border: none; height: 600px; overflow: auto;
  }
